import { startCase } from 'utils/strings';

export const formatCode = (code) => {
  const [first6] = code.split('-');
  return first6.toUpperCase();
};

export const formatBankAccount = (bankAccount) => {
  if (bankAccount.bank && bankAccount.bank.name) {
    const { bank, number } = bankAccount;
    return `${startCase(bank || bank.name)} • ${number.slice(-4)}`;
  }

  if (bankAccount.bank) {
    const { bank } = bankAccount;
    return `${startCase(bank || bank.name)} `;
  }

  if (bankAccount.number) {
    const { number } = bankAccount;
    return `• ${number.slice(-4)}`;
  }
  return '';
};

export const toRGBA = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
