import React from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import { toDollars } from 'utils/currency';
import { startCase } from 'utils/strings';
import styles from './choose-option.module.scss';
import config from '../../../../../config';
import { IoMdFlash } from '@react-icons/all-files/io/IoMdFlash';
import { RiTimeLine } from '@react-icons/all-files/ri/RiTimeLine';
const ChooseProcessingOption = ({
  group,
  bankAccount,
  processingOptions,
  processingOption,
  setProcessingOption,
  setProcessingOptions,
  setScreen,
}) => {
  const handleClick = (option) => {
    setProcessingOption(option);

    setTimeout(() => {
      setScreen('main');
    }, 300);
  };

  return (
    <div>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Opciones de procesamiento</span>
      </div>
      <div className={styles.content}>
        {group.products.map((option, index) => {
          // const Icon = option.icon;
          const isActive = processingOption.id === option.id;

          return (
            <div
              key={index}
              className={`${styles.option} ${isActive ? styles.active : ''}`}
              onClick={() => handleClick(option)}
            >
              {/*<Icon className={styles.optionIcon} />*/}

              <div className={styles.optionIcon}>
                {option?.groupedFees?.['INITIAL']?.value > 0 ? (
                  <IoMdFlash />
                ) : (
                  <RiTimeLine />
                )}
              </div>

              <div className={styles.infoContainer}>
                <p className={styles.name}>{startCase(option.name)}:</p>

                <p className={styles.fee}></p>
                <p className={styles.fee}>
                  &nbsp; • Uso de plataforma: $
                  {option?.groupedFees?.['LATE-CHARGE']?.value} Mensual
                </p>
                <p className={styles.fee}>
                  &nbsp; • Costo turbo:{' '}
                  {toDollars(option?.groupedFees?.['INITIAL']?.value || 0)}
                </p>

                <p className={styles.fee}>
                  &nbsp; • Entrega de anticipo hasta{' '}
                  {Number(
                    option.categories?.['PROCESSING-OPTIONS']?.atributos.filter(
                      (item) => item.slug === bankAccount.bank.slug,
                    )[0]?.valor || 0,
                  ) < 8
                    ? Number(
                        option.categories?.['PROCESSING-OPTIONS']?.atributos.filter(
                          (item) => item.slug === bankAccount.bank.slug,
                        )[0]?.valor || 0,
                      )
                    : (Number(
                        option.categories?.['PROCESSING-OPTIONS']?.atributos.filter(
                          (item) => item.slug === bankAccount.bank.slug,
                        )[0]?.valor || 0,
                      ) /
                        8) *
                        24 || 'No disponible'}{' '}
                </p>
                <p className={styles.fee}>&nbsp; &nbsp; horas Laborables </p>
                <hr></hr>
                <p className={styles.fee}>
                  &nbsp; &nbsp; <strong> Total:</strong>{' '}
                  {toDollars(
                    Number(option?.groupedFees?.['QUOTAS']?.value || 0) +
                      Number(option?.groupedFees?.['INITIAL']?.value || 0),
                  )}{' '}
                </p>
              </div>
              {isActive && <CheckIcon className={styles.checkIcon} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
/*
const processingOptionShape = PropTypes.exact({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fee: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  hoursAdjusted: PropTypes.number.isRequired,
  bankId: PropTypes.number.isRequired,
  expectedFundingDate: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
});

ChooseProcessingOption.propTypes = {
  processingOptions: PropTypes.arrayOf(processingOptionShape),
  processingOption: processingOptionShape,
  setProcessingOption: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
};
*/
export default ChooseProcessingOption;
