import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Loading from 'components/Loading';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { getOneLoan } from 'api/user';
import { startCase } from 'utils/strings';
import { toDollars } from 'utils/currency';
import { toCompactDate } from 'utils/dates';
import { formatCode, formatBankAccount, toRGBA } from '../../utils/helpers';
import statusColors from '../../utils/statusColors';
import useIsMounted from 'hooks/useIsMounted';
import styles from './loan-modal.module.scss';
import sorryPng from 'assets/decorative-images/Sorry.png';
import { fromISOToDateTime } from '../../../../utils/dates';

const ErrorScreen = ({ handleCloseRequest }) => {
  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <CloseIcon className={styles.closeIcon} onClick={handleCloseRequest} />
        <p>Error</p>
      </div>
      <div className={`${styles.contentWrapper} ${styles.errorWrapper}`}>
        <div>
          <h3 className={styles.errorTitle}>Error al cargar anticipo</h3>
          <p className={styles.errorMessage}>
            Lo sentimos, pero no pudimos cargar la información de este anticipo. Por
            favor, intentalo nuevamente más tarde.
          </p>
          <img src={sorryPng} alt="Error" className={styles.errorImage} />
        </div>
      </div>
    </div>
  );
};

const LoanContent = ({ loan, handleCloseRequest }) => {
  const bankAccount = {
    bank: loan.CreditAccountsPayDetails[0]?.bankSlug,
    number: loan.CreditAccountsPayDetails[0].bankAccountNumber,
  };

  const summaryItems = [
    { label: 'Fecha solicitud', value: fromISOToDateTime(loan.requestDate) },
    { label: 'Código de referencia', value: formatCode(loan.referenceCode) },
    { label: 'Fecha depósito', value: fromISOToDateTime(loan.expectedDepositDate) },
    {
      label: 'Cuenta de banco',
      value: formatBankAccount({ bank: bankAccount.bank }),
      value2: formatBankAccount({ number: bankAccount.number }),
      className: styles.bankAccount,
    },
    {
      label: 'Fecha vencimiento',
      value: toCompactDate(
        loan.CreditDetail[loan.CreditDetail.length - 1].expectedPaymentDateAndGraceDays,
      ),
    },
    { label: 'Tipo de procesamiento', value: startCase(loan?.product?.name) },
  ];

  const details = [
    { label: 'Anticipo', value: toDollars(loan.value2) },
    { label: 'Fee', value: toDollars(loan.fees2) },
    { label: 'Monto a retener', value: toDollars(loan.total2 -loan.CreditDetail.reduce((acc, curr) => acc + curr.totalPaid2CreditNote, 0)) },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <CloseIcon className={styles.closeIcon} onClick={handleCloseRequest} />
        <p className={styles.title}>Detalles del anticipo</p>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryHeader}>
              <div className={styles.loanAmount}>{toDollars(loan.value2)}</div>
              <div
                className={styles.statusContainer}
                style={{
                  backgroundColor: toRGBA(
                    statusColors[loan.creditHeaderStatus.name],
                    0.1,
                  ),
                  color: statusColors[loan.creditHeaderStatus.name],
                }}
              >
                <span>
                  {loan.creditHeaderStatus.name == 'incobrable'
                    ? startCase('vencido')
                    : startCase(loan.creditHeaderStatus.name)}
                </span>
              </div>
            </div>
            <div className={styles.gridContainer}>
              {summaryItems.map((item) => (
                <div key={item.label} className={item.className}>
                  <p className={styles.summaryLabel}>{item.label}</p>

                  <p className={styles.summaryValue}>{item.value}</p>
                  <p className={styles.summaryValue}>{item.value2}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.detailsWrapper}>
            <h3 className={styles.header}>Desglose</h3>
            <div className={styles.gridContainer}>
              {details.map((item) => (
                <div key={item.label} className={styles.className}>
                  <p className={styles.summaryLabel}>
                    {'   '}
                    {item.label}
                  </p>
                  <p className={styles.summaryValue}>
                    {'   '} {item.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <h3 className={styles.header}>Pagos</h3>

            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th>Anticipo</th>
                    <th >Nota de Crédito</th>
                    <th className={styles.hideOnMobile}>Promociones</th>

                    <th className={styles.hideOnMobile}>fee</th>
                    <th>Total</th>
                    <th className={styles.hideOnMobile}>Total Pagado</th>
                  </tr>
                </thead>
                <tbody>
                  {loan.CreditDetail.length > 0 ? (
                    loan.CreditDetail.map((payment, index) => (
                      <tr key={index}>
                        <td data-label="Fecha">{toCompactDate(payment.expectedPaymentDate)}</td>
                        <td data-label="Estado">{payment.creditDetailStatus.name}</td>
                        <td data-label="Anticipo">${startCase(payment.value2)}</td>
                        <td data-label="Nota de Crédito" className={styles.expectedPaymentDate}>
                          $ {payment.totalPaid2CreditNote}
                        </td>
                        <td data-label="Promociones" className={styles.hideOnMobile}>
                          {payment.FeesCreditDetail?.reduce((sum, feeDetail) => {
                            const promotions = feeDetail.fees?.FeesPromotion || [];
                            return (
                              sum +
                              promotions.reduce(
                                (promoSum, promo) =>
                                  promoSum + (Number(promo.result) || 0),
                                0,
                              )
                            );
                          }, 0) || 0}
                        </td>
                        <td data-label="Fee" className={styles.hideOnMobile}>
                          {toDollars(payment.fees2)}
                        </td>
                        <td data-label="Total">{toDollars(payment.total2)}</td>
                        <td data-label="Total Pagado" className={styles.hideOnMobile}>{toDollars(0.0)}</td>
                        
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className={styles.emptyStateRow}>
                        No se han realizado pagos
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoanModal = ({ loanId, isOpen, setIsOpen }) => {
  const isMounted = useIsMounted();
  const [loan, setLoan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseRequest = () => {
    setIsOpen(false);

    // Reset state
    setLoan(null);
    setIsLoading(true);
  };

  const handleOpen = async () => {
    setIsLoading(true);
    const start = Date.now();
    const minMS = 500;

    try {
      const data = await getOneLoan(loanId);

      if (isMounted.current) setLoan(data.data);
    } catch (error) {
      if (isMounted.current) setLoan(null);
    } finally {
      const end = Date.now();
      const time = end - start;
      if (time < minMS) {
        await new Promise((resolve) => setTimeout(resolve, minMS - time));
      }

      if (isMounted.current) setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseRequest}
      onAfterOpen={handleOpen}
      contentLabel="Detalles del Anticipo"
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <div className={styles.wrapper}>
        {isLoading ? (
          <Loading isFluid={true} />
        ) : loan ? (
          <LoanContent loan={loan} handleCloseRequest={handleCloseRequest} />
        ) : (
          <ErrorScreen handleCloseRequest={handleCloseRequest} />
        )}
      </div>
    </Modal>
  );
};

LoanModal.propTypes = {
  loanId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

LoanContent.propTypes = {
  loan: PropTypes.object.isRequired,
  handleCloseRequest: PropTypes.func.isRequired,
};

ErrorScreen.propTypes = {
  handleCloseRequest: PropTypes.func.isRequired,
};

export default LoanModal;
