import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import { RiCalendarCheckLine } from '@react-icons/all-files/ri/RiCalendarCheckLine';
import { RiCalendarScheduleFill } from '@react-icons/all-files/ri/RiCalendarCheckLine';

import AddBankAccount from 'components/AddBankAccount';
import CTA from 'components/CallToAction';
import { startCase, getLast4 } from 'utils/strings';
import useIsMounted from 'hooks/useIsMounted';
import BankLogo from '../../../../../components/BankLogo';
import Loading from 'components/Loading';
import bookPng from 'assets/decorative-images/Sorry.png';

import styles from './choose-group.module.scss';
import { RiSettingsLine } from '@react-icons/all-files/ri/RiSettingsLine';
import { getValidProduct } from '../../../../../api/loan';
import { set } from 'react-hook-form';

const ChooseGroup = ({
  amount,
  group,
  groups,
  handleGroupChange,
  setGroup,
  setGroups,
  setScreen,
}) => {
  const isMounted = useIsMounted();
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalidValue, setIsInvalidValue] = useState(null);
  const [groupsNew, setGroupsNew] = useState(groups);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await getValidProduct({ requestAmount: Number(amount) });
        if (response.data.productsAvailable.length === 0) {
          if (isMounted.current) {
            setIsInvalidValue(true);
            setIsLoading(false);
          }
          return;
        }
        setIsInvalidValue(false);
        const newGroups = groups
          .map((group) => ({
            ...group,
            products: group.products.filter((product) =>
              response.data.productsAvailable.some(
                (availableProduct) => availableProduct.productId === product.id,
              ),
            ),
          }))
          .filter((group) => group.products.length > 0);
        setGroupsNew(newGroups);

        if (isMounted.current) {
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted.current) {
          setIsLoading(false);
        }
        console.error('Error al obtener los plazos:', error);
      }
    };

    fetchQuotes();
  }, [amount, groups, isMounted]);

  const handleClick = (item) => {
    const currentGroupId = group.id;
    const newGroupId = item.id;

    setGroup(item);
    handleGroupChange(currentGroupId, newGroupId);
    setTimeout(() => setScreen('main'), 300);
  };

  const handleNewAccountSuccess = (item) => {
    const newGroupList = [item, ...groups];
    const currentGroupId = group.id;
    const newGroupId = item.id;

    if (isMounted.current) {
      setGroups(newGroupList);
      setGroup(item);
    }

    handleGroupChange(currentGroupId, newGroupId);
    setScreen('main');
  };

  const disableStyle = {
    pointerEvents: 'none',
    opacity: 0.5,
    visibility: 'hidden',
    display: 'none',
  };

  if (isInvalidValue) {
    return (
      <div className={styles.loader}>
        <div className={styles.container}>
          <h2 className={styles.title}>Cupo no disponible</h2>
          <p className={styles.subtitle}>El cupo solicitado no está disponible</p>
          <img className={styles.image} src={bookPng} alt="" />
        </div>
        <div className={styles.container}>
          <button className={styles.btnback} onClick={() => setScreen('main')}>
            Regresar
          </button>
        </div>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading isFluid={true} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Seleccionar Producto</span>
      </div>
      <div className={styles.accountListContainer}>
        <div className={styles.accountsList}>
          {groups.map((item, index) => (
            <div
              key={index}
              onClick={() => handleClick(item)}
              style={groupsNew.some((resp) => resp.id === item.id) ? null : disableStyle}
              className={`${styles.account} ${group.id === item.id ? styles.active : ''}`}
            >
              <RiCalendarCheckLine className={styles.processingOptionIcon} />
              <div className={styles.bankLogoContainer}></div>
              <div className={styles.info}>
                <div className={styles.bankName}>{startCase(item.name)}</div>
                <div className={styles.accountDetails}>
                  {item.name === 'Anticipo'
                    ? 'Un solo pago a final del mes'
                    : 'Puede Diferir hasta  meses 3'}
                  {/*'Puedes solicitar hasta $'} {item?.products[0]?.limitQuota*/}
                </div>
              </div>
              {group.id === item.id ? (
                <div>
                  <CheckIcon className={styles.checkIcon} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
     {/** 
      * 
      <div className={styles.addAccountButtonContainer}>
        <CTA
          text="Seleccionar"
          size="large"
          onClick={() => setIsAddAccountModalOpen(true)}
        />
      </div>
      <AddBankAccount
        isOpen={isAddAccountModalOpen}
        setIsOpen={setIsAddAccountModalOpen}
        callback={handleNewAccountSuccess}
      />
        */}
    </div>
  );
};

export default ChooseGroup;
