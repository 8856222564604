import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { getTyC } from 'api/termUser';

const useTerms = (initialLoadingState = false) => {
  const [isLoading, setIsLoading] = useState(initialLoadingState);

  const existsTermsConditionsToAccept = async (requiresAcceptanceTerms, error) => {
    setIsLoading(true);
    try {
      const tyC = await getTyC();
      if (!tyC?.data)
        toast.error(tyC.data.error || 'Error al obtener los términos y condiciones');
      else {
        if (tyC?.data?.requiresAcceptanceTerms) requiresAcceptanceTerms(true);
        else requiresAcceptanceTerms(false);
      }
    } catch (err) {
      toast.error(error?.response?.data || 'Error al obtener los términos y condiciones');
    } finally {
      setIsLoading(false);
    }
  };

  const existsTermsConditionsToAcceptAndOnboardingData = async (requiresAcceptanceTerms, error) => {
    setIsLoading(true);
    try {
      const tyC = await getTyC();
      if (!tyC?.data)
        toast.error(tyC.data.error || 'Error al obtener los términos y condiciones');
      else {
        if (tyC.data.onboardingnBiometrics == '' || tyC.data.onboardingnBiometrics == null || tyC.data.onboardingnTyc == '' || tyC.data.onboardingnTyc == null) {
          toast.error('Estamos procesando tu información, por favor espera unos minutos mientras completamos los pasos necesarios', { duration: 10000 });
          return
        }

        if (tyC?.data?.requiresAcceptanceTerms) requiresAcceptanceTerms(true);
        else requiresAcceptanceTerms(false);
      }
    } catch (err) {
      toast.error(error?.response?.data || 'Error al obtener los términos y condiciones');
    } finally {
      setIsLoading(false);
    }
  };


  return {
    existsTermsConditionsToAccept,
    existsTermsConditionsToAcceptAndOnboardingData,
    isLoading,
  };
};

export default useTerms;
