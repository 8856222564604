import axios from 'axios';
import config from '../config';

const axiosApiInstance = axios.create({
  baseURL: config.apiUrl,
  // withCredentials: false,
  'content-type': 'application/json',
});

export default axiosApiInstance;
