import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { RiArrowRightSLine as RightArrow } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { RiErrorWarningLine as WarningIcon } from '@react-icons/all-files/ri/RiErrorWarningLine';
import { RiSettingsLine } from '@react-icons/all-files/ri/RiSettingsLine';
import { RiHandCoinLine } from '@react-icons/all-files/ri/RiHandCoinLine';
import { RiExchangeFundsFill } from '@react-icons/all-files/ri/RiExchangeFundsFill';
import { FaSortNumericUp } from '@react-icons/all-files/fa/FaSortNumericUp';

import CTA from 'components/CallToAction';
import useStore, { set } from 'store';
import { toDollars, isValidDollarAmount } from 'utils/currency';
import { startCase, getLast4 } from 'utils/strings';
import styles from './main-screen.module.scss';
import BankLogo from '../../../../../components/BankLogo';
import { generateQuotas } from '../../../../../api/loan';
import config from '../../../../../config';
import useIsMounted from 'hooks/useIsMounted';

const MainScreen = ({
  processingOption,
  bankAccount,
  group,
  quote,
  setLoan,
  setTermId,
  setScreen,
  handleModalClose,
  amount,
  setAmount,
}) => {
  const isMounted = useIsMounted();

  const { product } = config;
  const { groupProduct } = config;
  const amountInput = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRefactor, setIsRefactor] = useState(amount);

  const infoUser = useStore((state) => state.user.data);
  const user = infoUser.wallet;
  const [quotaTransaction, setQuotaTransaction] = useState(0);
  const [quoteInfo, setQuoteInfo] = useState([]);
  const [availableQuota, setAvailableQuota] = useState(0);

  const [isContinue, setIsContinue] = useState(true);

  useEffect(() => {
    const limitQuota = group?.products[0]?.limitQuota;
    setAvailableQuota(limitQuota);
    const isAmountValid = amount > 0 || amount <= limitQuota;
    const isQuoteValid = !!quote.quoteNumber;

    switch (processingOption.code) {
      case product.anticipo:
      case product.anticipoTurbo:
        setIsContinue(!isAmountValid);
        break;
      case product.pagoPlazo:
      case product.pagoPlazoTurbo:
        setIsContinue(!(isAmountValid && isQuoteValid));
        break;
      default:
        setIsContinue(true);
        break;
    }

    if (isRefactor !== amount) {
      quote.quoteNumber = undefined;
      setIsRefactor(amount);
    }
  }, [amount, quote, group, processingOption]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsSubmit(true);
    const start = Date.now();
    const minSecs = 1000;

    try {
      /*    
      const loan = await createLoan({
        requestedAmount: amount,
        bankAccountId: bankAccount.id,
        groupId: group.id,
        processingOptionId: processingOption.id || '',
      });*/

      const loan = {
        requestedAmount: amount,
        bankAccount: bankAccount,
        groupId: group,
        processingOptionId: processingOption,
        quoteInfo: quoteInfo.data,
        quoteNumber: quote.quoteNumber || 1,
      };

      const end = Date.now();
      const time = end - start;
      if (time < minSecs) {
        await new Promise((resolve) => setTimeout(resolve, minSecs - time));
      }

      setLoan(loan);
      const terms = [];
      setTermId(terms.termId);
      setIsLoading(false);
      setScreen('review');
    } catch (error) {
      toast.error(error.response?.data);
      setIsLoading(false);
    }
  };

  const setAmountInputWidth = (amountValue) => {
    const numWidth = 34;
    const dotWidth = 14;
    amountValue = String(amountValue);

    const [dollars, cents] = amountValue.split('.');
    const widthDollars = dollars.length * numWidth;
    const widthCents = cents ? cents.length * numWidth : 0;
    const widthDot = amountValue.includes('.') ? dotWidth : 0;
    const totalWidth = widthDollars + widthCents + widthDot;

    amountInput.current.style.width = `${totalWidth}px`;
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;

    if (value > Number(availableQuota)) {
      return;
    }

    if (!isValidDollarAmount(value)) return;

    setAmountInputWidth(value);
    setAmount(value);
  };

  const body = {
    productId: processingOption.id,
    requestAmount: Number(amount || 1),
    numberQuotas: quote.quoteNumber || 1,
    bankSlug: bankAccount?.bank.slug,
  };

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        let response = await generateQuotas(body);
        if (isMounted.current) {
          setQuoteInfo(response || []);
          if (response) {
            setLoan((prevLoan) => ({
              ...prevLoan,
              quoteInfo: response,
              requestedAmount: amount,
            }));
          }
        }
      } catch (error) {
        setQuoteInfo([]);
      }
    };

    if (amount > 0) {
      fetchQuotes();
    }
  }, [amount, quote, isSubmit]);

  useEffect(async () => {
    setAmountInputWidth(amount);
    const quotaTransactionData = []; //await getQuotaTransaction();
    setQuotaTransaction(quotaTransactionData.quotaTransaction || 0);
  }, []);

  useEffect(() => {
    if (amountInput.current) {
      amountInput.current.focus();
    }
  }, [amountInput]);
  return (
    <div className={amount > 0 ? styles.wrapper : styles.default}>
      <div className={styles.header}>
        <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        <span>Nuevo anticipo</span>
      </div>
      <div className={styles.content}>
        <div className={styles.amountWrapper}>
          <div>
            <div className={styles.amountContainerOuter}>
              <div
                className={`${styles.amountContainerInner} ${amount.length > 0 ? styles.active : ''
                  }`}
              >
                <div className={styles.dollarSign}>$</div>
                <input
                  ref={amountInput}
                  className={styles.amountInput}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  pattern="[0-9]*"
                  placeholder="0"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </div>
            <p className={styles.amountMessage}>{`Puedes solicitar hasta ${toDollars(
              availableQuota,
            )}`}</p>
            {quotaTransaction > 0 && amount > quotaTransaction && (
              <>
                <br />
                <span
                  style={{
                    color: 'rgb(255, 143, 14)',

                    background: '#ff8f0e',
                    color: '#000000',
                    fontSize: '0.9rem',
                    fontWeight: '700',
                    padding: '0.25rem 0.4rem',
                    borderRadius: '6px',
                  }}
                >
                  {`El monto máximo por transacción es ${toDollars(quotaTransaction)}`}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.optionsContainer}>
            <div
              className={styles.option}
              onClick={amount > 0 ? () => setScreen('chooseGroup') : undefined}
            >
              {/* TIPO DE PROCESAMIENTO */}
              <div className={styles.optionImageContainer}>
                <RiHandCoinLine className={styles.processingOptionIcon} />
              </div>
              <div className={styles.optionDescriptionContainer}>
                <p className={styles.optionDescriptionTop}>{'Producto'}</p>
                <p className={styles.optionDescriptionBottom}>
                  {amount > 0 ? group.name : 'Se debe ingresar un monto'}
                </p>
              </div>
              <div className={styles.optionIconContainer}>
                <RightArrow
                  className={styles.optionIcon}
                  style={{ opacity: amount > 0 ? 1 : 0.5 }}
                />
              </div>
            </div>
            {/*  processingOption */}
            <div
              className={styles.option}
              onClick={amount > 0 ? () => setScreen('chooseOption') : undefined}
            >
              <div className={styles.optionImageContainer}>
                <RiExchangeFundsFill className={styles.processingOptionIcon} />
              </div>
              <div className={styles.optionDescriptionContainer}>
                <p className={styles.optionDescriptionTop}>
                  {startCase('Opciones de procesamiento')}
                </p>
                <p className={styles.optionDescriptionBottom}>{processingOption.name}</p>
              </div>
              <div className={styles.optionIconContainer}>
                <RightArrow className={styles.optionIcon} />
              </div>
            </div>
            {/* Plazos */}
            {group?.code === groupProduct.pagoPlazo && amount > 0 && (
              <div className={styles.option} onClick={() => setScreen('chooseQuote')}>
                <div className={styles.optionImageContainer}>
                  <FaSortNumericUp className={styles.processingOptionIcon} />
                </div>
                <div className={styles.optionDescriptionContainer}>
                  <p className={styles.optionDescriptionTop}>Plazos</p>
                  <p className={styles.optionDescriptionBottom}>
                    {quote?.quoteNumber ? ` ${quote.quoteNumber} meses` : 'Seleccionar'}
                  </p>
                </div>

                <div className={styles.optionIconContainer}>
                  <RightArrow className={styles.optionIcon} />
                </div>
              </div>
            )}
            {group?.code === groupProduct.pagoPlazo && quote?.quoteNumber > 1 && (
              <table
                style={{ width: '100%', borderCollapse: 'collapse', marginTop: '1rem' }}
              >
                <thead>
                  <tr style={{ borderBottom: '2px solid #ddd', textAlign: 'center' }}>
                    <th style={{ padding: '8px' }}>Valor</th>
                    <th style={{ padding: '8px' }}>Fee</th>
                    <th style={{ padding: '8px' }}>Fecha de Pago</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteInfo?.data?.quotas?.map((item, index) => (
                    <tr
                      key={index}
                      style={{ borderBottom: '1px solid #ddd', textAlign: 'center' }}
                    >
                      <td style={{ padding: '8px' }}>${item.value}</td>
                      <td style={{ padding: '8px' }}>${item.feeWithPromotions}</td>
                      <td style={{ padding: '8px' }}>
                        {item.expectedPaymentDateAndGraceDays}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {/*BANK */}
            <div
              className={styles.option}
              onClick={amount > 0 ? () => setScreen('chooseAccount') : undefined}
            >
              <div className={styles.optionImageContainer}>
                {
                  <BankLogo
                    logo={bankAccount.bank.slug}
                    styles={{}}
                    alt={`${bankAccount.bank.name} logo`}
                  />
                }
              </div>
              {/*BANK */}
              <div className={styles.optionDescriptionContainer}>
                <p className={styles.optionDescriptionTop}>
                  {startCase(bankAccount.bank.name)}
                </p>
                <p className={styles.optionDescriptionBottom}>
                  {/*JSON.stringify(bankAccount)*/}
                  {startCase(bankAccount?.accountType?.name)} •{' '}
                  {getLast4(bankAccount.number)}
                </p>
              </div>
              <div className={styles.optionIconContainer}>
                <RightArrow className={styles.optionIcon} />
              </div>
            </div>

            <p>{/*JSON.stringify(!amount)*/} </p>
          </div>
          <CTA
            text="Continuar"
            size="large"
            onClick={() => {
              if (!amount || amount <= 0) {
                toast.error('Debes ingresar el valor de anticipo a solicitar.');
              } else if (group?.code === groupProduct.pagoPlazo && !quote?.quoteNumber) {
                toast.error('Debes seleccionar el plazo del anticipo.');
              } else {
                handleSubmit();
              }
            }}
            isLoading={isLoading}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};
/*
MainScreen.propTypes = {
  amount: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  setLoan: PropTypes.func.isRequired,
  setTermId: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  bankAccount: PropTypes.exact({
    id: PropTypes.number.isRequired,
    bank: PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
    }),
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
  }),
  processingOption: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fee: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    hoursAdjusted: PropTypes.number.isRequired,
    bankId: PropTypes.number.isRequired,
    expectedFundingDate: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.func,
  }),
};*/
export default MainScreen;
