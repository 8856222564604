import React from 'react';
import useStore from 'store';
import wave from './assets/wave.svg';
import styles from './summary.module.scss';
import { toDollars } from '../../../../utils/currency';
import { FaWallet, FaChartLine, FaClock, FaShieldAlt } from 'react-icons/fa';
import { BsCreditCard2Front } from 'react-icons/bs';

const Summary = () => {
  const user = useStore((state) => state.user);
  const { firstName } = user.data;
  const { quote,availableQuota = 0,usedQuota } = user.data.wallet;

  const available = Number(availableQuota).toFixed(2);
  const used = usedQuota;
  const cuota =quote;
  
  // Estos datos deberían venir de tu API, por ahora son simulados
  const creditStats = {
    score: 750,
    nextPayment: '28 Feb',
    paymentAmount: toDollars(1500, 0),
    usedQuota: used,
    progress: `${(available/cuota) * 100}%` ,
  };
  return (
    <div className={styles.wrapper}>
      <img src={wave} alt="" className={styles.wave} aria-hidden="true" />      
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.welcomeSection}>
            <h1 className={styles.welcomeMessage}>
              Te damos la bienvenida,
               <span className={styles.userName}>{firstName}</span>
            </h1>
            <p className={styles.welcomeSubtext}>Tu resumen financiero de hoy</p>
          </div>
          {/* <div className={styles.creditScoreCard}>
            <div className={styles.scoreIcon}>
              <FaShieldAlt />
            </div>
            <div className={styles.scoreInfo}>
              <span className={styles.scoreLabel}>Score Crediticio</span>
              <span className={styles.scoreValue}>{creditStats.score}</span>
            </div>
          </div> */}
        </header>
        
        <main className={styles.mainContent}>
          <div className={styles.quotaSection}>
            <div className={styles.availableQuotaContainer}>
              <div className={styles.quotaHeader}>
                <div className={styles.availableQuotaIcon}>
                  <BsCreditCard2Front />
                </div>
                <div className={styles.quotaHeaderText}>
                  <p className={styles.availableQuotaTitle}>Cupo máximo disponible</p>
                  <p className={styles.availableQuotaValue}>{available}</p>
                </div>
              </div>
              <div className={styles.quotaProgress}>
                <div className={styles.progressBar}>
                  <div 
                    className={styles.progressFill} 
                    style={{ width: creditStats.progress }}
                  />
                </div>
                {/* <div className={styles.quotaStats}>
                  <span>Usado: {creditStats.usedQuota}</span>
                  <span>Disponible: {available}</span>
                </div> */}
              </div>
            </div>
            
            {/* <div className={styles.statsGrid}>
              <div className={styles.statCard}>
                <FaChartLine className={styles.statIcon} />
                <div className={styles.statInfo}>
                  <span className={styles.statLabel}>Próximo Pago</span>
                  <span className={styles.statValue}>{creditStats.nextPayment}</span>
                </div>
              </div>
              
              <div className={styles.statCard}>
                <FaClock className={styles.statIcon} />
                <div className={styles.statInfo}>
                  <span className={styles.statLabel}>Monto Total Adeudado</span>
                  <span className={styles.statValue}>{creditStats.paymentAmount}</span>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Summary;
