import axios from '../lib/axios';
/*
export const getTyC = () => {
  return axios.get('/term-user/getTyC');
};*/

export const getTyC = () => {
  //return axios.get('/docs/validate-doc-tyc');
  return axios.get('/docs/template/tyc');
};

export const createTyC = ({ termId }) => {
  return axios.post('/docs/signed/tyc', { termId });
};

export const getTyR = () => {
  return axios.get('/docs/template/tyr');
};

export const needsAcceptTermsNewUser = ({ email, password }) => {
  return axios.post('/term-user/needsAcceptTermsNewUser', { email, password });
};

export const getTyCToNewUser = ({ email, password }) => {
  return axios.post('/term-user/getTyCToNewUser', { email, password });
};

export const getTyRByUserLoanTerm = (body) => {
  return axios.post(`/credit/template/tyr`,body);
};
export const getPromissory = () => {
  return axios.get(`/docs/template/promissory`);
};
