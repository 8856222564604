import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { toast } from 'react-hot-toast';
import CTA from 'components/CallToAction';
import { confirmLoanRequest } from 'api/loan';
import { toDollars } from 'utils/currency';
import { getMonthName, toDateWithTime } from 'utils/dates';
import { getLast4 } from 'utils/strings';
import config from 'config';
import styles from './review.module.scss';
import Checkbox from 'components/Inputs/Checkbox';
import { TermsTYRModal } from 'components/Terms/Terms';
import { startCase } from '../../../../../utils/strings';

const Review = ({ termId, loan, setLoan, setIsCompleted, setScreen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [tyRModalOpen, setTyRModalOpen] = useState(false);
  const [datatyr, setDatatyr] = useState(null);

  useEffect(() => {
    if (loan) {
      const body = {
        productId: loan?.processingOptionId?.id,
        bankId: loan?.bankAccount?.bankAccountId,
        requestAmount: Number(loan.requestedAmount),
        numberQuota: loan?.quoteInfo?.quotas.length,
      };
      setDatatyr(body);
    }
  }, [loan]);

  const handleClick = async () => {
    if (!isAgreed) {
      toast.error('Debes aceptar los términos y condiciones para continuar');
      return;
    }

    setIsLoading(true);
    const start = Date.now();
    const minMS = 3000;

    try {
      const confirmedLoan = await confirmLoanRequest(datatyr);

      setLoan(prev => ({
        ...prev,
        ...confirmedLoan
      }));

      const end = Date.now();
      const time = end - start;
      const timeLeft = minMS - time;

      if (timeLeft > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeLeft));
      }

      setIsCompleted(true);
      setIsLoading(false);
      setScreen('confirmation');
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.message || 'Error al procesar la solicitud');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
          Revisa tu solicitud
        </div>
        <div className={styles.content}>
          <div className={styles.topContent}>
            <div className={styles.amountWrapper}>
              <div className={styles.amount}>
                <div className={styles.amountSymbol}>$</div>
                <div className={styles.amountNumber}>
                  {Number(loan.quoteInfo?.total).toFixed(2)}
                </div>
              </div>
              {loan?.quoteInfo?.quotas.length <= 1 && (
                <div className={styles.amountCaption}>Valor total a retener</div>
              )}
              {loan?.quoteInfo?.quotas.length > 1 && (
                <div className={styles.amountCaption}>Valor total a retener </div>
              )}
            </div>
          </div>
          <div className={styles.bottomContent}>
            <div className={styles.detailsContainer}>
              <div className={styles.detail}>
                <div className={styles.detailHeader}>Anticipo</div>
                <div className={styles.detailBody}>
                  <div className={styles.detailItem}>
                    <span>Cuenta</span>
                    <span>
                      {startCase(loan?.bankAccount?.bank?.name || '')} •{' '}
                      {getLast4(loan?.bankAccount?.number)}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span>Fecha a recibir</span>
                    <span>{loan?.quoteInfo?.expectedDepositDate}</span>
                  </div>
                </div>
              </div>

              {loan?.quoteInfo?.quotas.length <= 1 ? (
                <div className={styles.detail}>
                  <div className={styles.detailHeader}>Retención</div>
                  <div className={styles.detailBody}>
                    <div className={styles.detailItem}>
                      <span>Anticipo</span>
                      <span>{toDollars(loan.quoteInfo.quotas[0].value)}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <span>Uso de plataforma</span>
                      <span>{toDollars(loan.quoteInfo.quotas[0].quotesFees)}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <span>Tarifa de procesamiento</span>
                      <span>{toDollars(loan.quoteInfo.quotas[0].initialFees)}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <span>Total a retener</span>
                      <span>{toDollars(loan.quoteInfo.quotas[0].totalValue)}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ overflowX: 'auto' }}>
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      marginTop: '1rem',
                    }}
                  >
                    <thead>
                      <tr style={{ borderBottom: '2px solid #ddd', textAlign: 'center' }}>
                        <th style={{ padding: '8px' }}>Capital</th>
                        <th style={{ padding: '8px' }}>Fee</th>
                        <th style={{ padding: '8px' }}>Promoción</th>
                        <th style={{ padding: '8px' }}>Total Fee</th>
                        <th style={{ padding: '8px' }}>Total Cuota</th>
                        <th style={{ padding: '8px' }}>Fecha de Pago</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loan?.quoteInfo?.quotas.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: '1px solid #ddd', textAlign: 'center' }}
                        >
                          <td style={{ padding: '8px' }}>${item.value}</td>
                          <td style={{ padding: '8px' }}>${item.fees}</td>
                          <td style={{ padding: '8px' }}>{item.diffPromotionsFormat}</td>
                          <td style={{ padding: '8px' }}>{item.feeWithPromotions}</td>
                          <td style={{ padding: '8px' }}>
                            {item.totalValueWhitPromotions.toFixed(2)}
                          </td>
                          <td style={{ padding: '8px' }}>
                            {item.expectedPaymentDateAndGraceDays}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className={styles.footer}>
              <Checkbox
                isChecked={isAgreed}
                onChange={() => setIsAgreed(!isAgreed)}
                label={
                  <span className={styles.disclaimer}>
                    {`He leído y acepto los `}
                    {/* <a href={config.termsUrl} rel="noreferrer" target="_blank">
                      términos de referencia del contrato
                    </a> */}
                    <a
                      style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => {
                        setTyRModalOpen(true);
                      }}
                    >
                      términos de referencia del contrato
                    </a>
                  </span>
                }
              />
              <CTA
                text="Solicitar anticipo"
                size="large"
                isLoading={isLoading}
                disabled={!loan || !isAgreed}
                //disabled={false}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
      <TermsTYRModal
        termId={termId}
        body ={datatyr}
        loanId={loan.id}
        isOpen={tyRModalOpen}
        setIsOpen={setTyRModalOpen}
      />
    </>
  );
};
/*
Review.propTypes = {
  termId: PropTypes.number,
  loan: PropTypes.object,
  setLoan: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  setIsCompleted: PropTypes.func.isRequired,
};
*/
export default Review;
