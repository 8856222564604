import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosApiInstance from 'lib/axios'; // Usa la instancia personalizada
import routes from 'routes/routeList';
import useUser from 'hooks/useUser';
import useStore from 'store';
import toast from 'react-hot-toast';
const useInterceptors = () => {
  const navigate = useNavigate();
  const { clearCurrentUser } = useUser();
  const { setNetworkError } = useStore();

  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    console.error(error.response);
    // Manejo de errores de red
    if (!error.response) {
      setNetworkError(error);
    }

    if (error.response?.status === 400) {
      toast.error(error.response.data.message);
    }

    // Manejo de error 401
    if (error.response?.status === 401) {
      navigate(routes.login);
      clearCurrentUser();
      // setNetworkError(error);
    }

    // //Manejo error 403
     if (error.response?.status === 403){
       toast.error(error?.response?.data?.message);
     }


    if (error.response?.status === 500) {
      setNetworkError(error);
      // navigate(routes.login);
      //clearCurrentUser();
    }

    throw error;
  };

  useEffect(() => {
    const requestInterceptor = axiosApiInstance.interceptors.request.use(
      (config) => {
        config.headers['app'] = 'MISU';

        const token = localStorage.getItem('authToken');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseInterceptor = axiosApiInstance.interceptors.response.use(
      onSuccess,
      onError,
    );

    return () => {
      axiosApiInstance.interceptors.request.eject(requestInterceptor);
      axiosApiInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return null;
};

export default useInterceptors;
