import axios from '../lib/axios';

/*export const getUser = () => {
  return axios.get('/me');
};*/

export const getUser = () => {
  return axios.get('/user/profile/');
};

export const updateUser = (data) => {
  return axios.put('/user/update-profile', data);
};

export const updatePassword = (data) => {
  return axios.put('/me/password', data);
};

export const getUserBankAccounts = () => {
  return axios.get('/user/bank-accounts');
};

/*
export const getAllLoans = (page, limit) => {
  return axios.get(`/me/loans?page=${page}&limit=${limit}`);
};*/

export const getAllLoans = (page, limit) => {
  return axios.post(`/credit/findAll/pagination/filters?page=${page}&take=${limit}`);
};

export const getOneLoan = (loanId) => {
  return axios.get(`/credit/header/${loanId}`);
};

export const getLoanDefaults = () => {
  // axios.get('/me/loan-defaults');

  return axios.get('/product');
};
