import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // Para redirección
import styles from './show-message.module.scss';
import bookPng from 'assets/decorative-images/Book.png';

const ShowMessage = ({ title, message, showButton, path, buttonText, onClose }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (path) {
      navigate(path);
    }
    if (onClose) {
      onClose();
    }
  };
  const styleButton = {
    margin: '10px 0',
    backgroundColor: '#736ced',
    color: 'white',
    borderRadius: '50px',
    padding: '10px 20px',
    cursor: 'pointer',
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{message}</p>
        <img className={styles.image} src={bookPng} alt="" />
        {showButton && (
          <button
            style={styleButton}
            className={styles.button}
            onClick={handleButtonClick}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

ShowMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  path: PropTypes.string,
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
};

ShowMessage.defaultProps = {
  showButton: false,
  path: '',
  buttonText: 'Ir',
  onClose: null,
};

export default ShowMessage;
