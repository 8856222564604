import axios from '../lib/axios';

export const getAllBanks = () => {
  //return axios.get('/banks');
  return axios.get('/company/bank-accounts');
};

export const getProcessingOptions = (bankId) => {
  return axios.get(`/banks/${bankId}/processing-options`);
};
