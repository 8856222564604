import React, { useState, useEffect } from 'react';
import config from 'config';
import useStore from 'store';

import Navbar from 'components/Navbar';
import NoAccess from './components/NoAccess';
import Summary from './components/Summary';
import LoanList from './components/LoanList';
import LoanModal from './components/LoanModal';
import TermsModal from 'components/Terms';
import useTerms from 'hooks/useTerms';
import TyCNoAccess from './components/TyCNoAccess';
import PendingOnboarding from './components/PendingOnboarding';

import ShowMessage from './components/ShowMessage';
import OnboardingModal from 'components/Onboarding';
import Footer from './Footer';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [tyCModalOpen, setTyCModalOpen] = useState(false);
  const { existsTermsConditionsToAccept } = useTerms();

  const [onboardingOpen, setOnboardingOpen] = useState(false);

  const SUPPORT_PHONE = '093 903 8128';
  const COLLECTIONS_PHONE = '0939705512';
  const handleModalClose = () => {
    // Force reload to show the new loan in the list
    if (isCompleted) {
      return window.location.reload(false);
    }

    // Close the modal
    setIsFormOpen(false);

    // Reset state
    setIsLoading(true);
    setDefaultOptions(null);
  };

  useEffect(() => {
    if (user.isPendingRegistration) setOnboardingOpen(true);

    existsTermsConditionsToAccept(
      (requiresAcceptanceTerms) => {
        if (requiresAcceptanceTerms) {
          setTyCModalOpen(true);
        }
      },
      (error) => toast.error(error),
    );
  }, []);

  const user = useStore((state) => state.user.data);
  const [showLiquidatedMessage, setShowLiquidatedMessage] = useState(true); //isLiquidated
  const [showBlockedMessage, setShowBlockedMessage] = useState(true); //isBlocked
  const [showDefaulterMessage, setShowDefaulterMessage] = useState(true); //isDefaulter
  
  const [showPending, setShowPending] = useState(true); //isDefaulter
  const [showDisabled, setShowDisabled] = useState(true); //isDefaulter
  const [showCanceled, setShowCanceled] = useState(true); //isDefaulter
  const [showReview, setShowReview] = useState(true); //isDefaulter


  const { approved, pending, disabled } = config.userStatuses;
  const { CANCELED } = config.UserPerfilaStatuses;

  //isLiquidated
  if (user.isLiquidated && showLiquidatedMessage) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={'Usuario Liquidado'}
          message={`Hola, nos enteramos de que ya no estás en tu empresa actual. Si ya estás trabajando en una nueva empresa, contáctanos a soporte Misú al ${SUPPORT_PHONE} para actualizar tu información.`}
          showButton={true}
          onClose={() => setShowLiquidatedMessage(false)}
          buttonText={'Aceptar'}
        />
      </>
    );
  }
  //isBlocked
  if (user.isBlocked && showBlockedMessage) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={'Usuario Bloqueado'}
          message={`Tu cuenta está bloqueada. Para conocer más detalles, por favor escribe al WhatsApp de Cobranzas ${COLLECTIONS_PHONE}. ¡Estamos para ayudarte!`}
          onClose={() => setShowBlockedMessage(false)}
          buttonText={'Aceptar'}
          showButton={true}
        />
      </>
    );
  }
  //isDefaulter
  if (user.isDefaulter && showDefaulterMessage) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={'Usuario Bloqueado'}
          message={`Tienes pagos pendientes. Regulariza tu situación para seguir utilizando nuestros servicios. Por favor escribe al WhatsApp de Cobranzas ${COLLECTIONS_PHONE}.`}
          showButton={true}
          onClose={() => setShowDefaulterMessage(false)}
          buttonText={'Aceptar'}
        />
      </>
    );
  }

  //Desahibilitado / comentado a petición del señor Juan Martin y Adriana Hernandez
  //user
  // if (user.onboardingnBiometrics === null || user.onboardingnBiometrics === '') {
  //   return (
  //     <>
  //       <Navbar />
  //       <ShowMessage
  //         title={''}
  //         message={
  //           '¡Muy pronto podrás disfrutar al máximo de nuestros servicios una vez que completes el proceso de registro! Estamos emocionados de que seas parte de Misú y de brindarte una experiencia excepcional.'
  //         }
  //         onClose={() => setShowDefaulterMessage(false)}
  //         showButton={false}
  //       />
  //     </>
  //   );
  // }
  // if (user.onboardingnTyc === null || user.onboardingnTyc === '') {
  //   return (
  //     <>
  //       <Navbar />
  //       <ShowMessage
  //         title={''}
  //         message={
  //           '¡Muy pronto podrás disfrutar al máximo de nuestros servicios una vez que completes el proceso de registro! Estamos emocionados de que seas parte de Misú y de brindarte una experiencia excepcional.'
  //         }
  //         onClose={() => setShowDefaulterMessage(false)}
  //         showButton={false}
  //       />
  //     </>
  //   );
  // }
  //Pendiente
  if (user.userStatus.code === 'PENDING' && showPending) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={''}
          message={`Tu cuenta está en proceso de activación. Para más información, Si tienes dudas, contáctanos a soporte Misú al ${SUPPORT_PHONE}.`}
          onClose={() => setShowPending(false)}
          showButton={true}
          buttonText={'Aceptar'}

        />
      </>
    );
  }
  //Deshabilitado
  if (user.userStatus.code === 'DISABLED' && showDisabled) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={''}
          message={`Tu cuenta ha sido deshabilitada. Si necesitas más detalles, contáctanos a soporte Misú al ${SUPPORT_PHONE}.`}
          onClose={() => setShowDisabled(false)}
          showButton={true}
          buttonText={'Aceptar'}

        />
      </>
    );
  }

  if (user.userPerfilaStatus.code === 'rejected' && showCanceled) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={''}
          message={`Tu perfil no cumple con los requisitos por ahora. Si tienes dudas, por favor escribe al WhatsApp ${COLLECTIONS_PHONE}.`}
          onClose={() => setShowCanceled(false)}
          buttonText={'Aceptar'}
          showButton={true}
        />
      </>
    );
  }
  //Deshabilitado
  if (user.userPerfilaStatus.code === 'in-review' && showReview) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={''}
          message={`Estamos revisando tu perfil. Te avisaremos pronto con novedades. Si necesitas ayuda, por favor escribe al WhatsApp ${COLLECTIONS_PHONE}.`}
          onClose={() => setShowReview(false)}
          buttonText={'Aceptar'}
          showButton={true}
          path={''}
        />
      </>
    );
  }

  //perfila canceled
  if (user.userPerfilaStatus.code === CANCELED && showCanceled) {
    return (
      <>
        <Navbar />
        <ShowMessage
          title={'Usuario '}
          message={`Estamos revisando tu perfil. Te avisaremos pronto con novedades. Si necesitas ayuda, por favor escribe al WhatsApp ${COLLECTIONS_PHONE}.`}
          onClose={() => setShowCanceled(false)}
          showButton={true}
          buttonText={'Aceptar'}
        />
      </>
    );
  }

  if (user.requireTyC)
    return (
      <>
        <Navbar />
        <TyCNoAccess />
      </>
    );

  if (user.approvalStatus === disabled)
    return (
      <>
        <Navbar />
        <NoAccess />
      </>
    );

  return (
    <>
      <Navbar />
      <Summary />
      <LoanList setLoanId={setLoanId} setIsModalOpen={setIsModalOpen} />
      <br></br>
      <LoanModal loanId={loanId} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <TermsModal type={'TYC'} isOpen={tyCModalOpen} setIsOpen={setTyCModalOpen} />
    </>
  );
};

export default Home;
