import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import AddBankAccount from 'components/AddBankAccount';
import CTA from 'components/CallToAction';
import { startCase, getLast4 } from 'utils/strings';
import useIsMounted from 'hooks/useIsMounted';
import styles from './choose-account.module.scss';
import BankLogo from '../../../../../components/BankLogo';
import { getAllBanks } from 'api/bank';
import { getBankAccountsUser } from '../../../../../api/bankAccount';
import { set } from 'react-hook-form';

const ChooseBankAccount = ({
  bankAccounts,
  bankAccount,
  handleAccountChange,
  setBankAccount,
  setBankAccounts,
  setScreen,
}) => {
  const isMounted = useIsMounted();
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [reqBank, setReqBank] = useState(null);
  const handleClick = async (account) => {
    const currentBankId = bankAccount.id;
    const newBankId = account.id;
    setBankAccount(account);
    handleAccountChange(currentBankId, newBankId);
    setTimeout(() => setScreen('main'), 300);
  };

  const handleNewAccountSuccess = (account) => {
    const newAccountList = [account, ...bankAccounts];
    const currentBankId = bankAccount.bank.id;
    const newBankId = account.bank.id;

    if (isMounted.current) {
      setBankAccounts(newAccountList);
      setBankAccount(account);
    }

    handleAccountChange(currentBankId, newBankId);
    setScreen('main');
  };

  useEffect(async () => {
    try {
      const data = await getBankAccountsUser();
      const optionsBank = data.data.map((item) => ({
        accountType: item.bankType,
        bank: item.bank,
        bankAccountId: item.id,
        number: item.accountNumber,
        logo: '',
        isDefault: item.isDefault,
        status: item.status,
      }));

      setBankAccounts(optionsBank);
      //      if (isMounted.current) setAccountTypeOptions(optionsAccountTypeOptions);
      //    if (isMounted.current) setBankOptions(optionsBank);
    } catch (error) {
      //if (isMounted.current) toast.error('Error al cargar lista de bancos');
    }
  }, [isAddAccountModalOpen]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Seleccionar Cuenta Bancaria</span>
      </div>
      <div className={styles.accountListContainer}>
        <div className={styles.accountsList}>
          {bankAccounts.map((account, index) => (
            <div
              key={index}
              onClick={() => handleClick(account)}
              className={`${styles.account} ${
                bankAccount?.number === account?.number ? styles.active : ''
              }`}
            >
              <div className={styles.bankLogoContainer}>
                <BankLogo
                  logo={account.bank.slug}
                  styles={{}}
                  alt={`${account.bank.name} logo`}
                />
              </div>
              <div className={styles.info}>
                <div className={styles.bankName}>{startCase(account?.bank?.name)}</div>
                <div className={styles.accountDetails}>
                  {startCase(account?.accountType?.name)} •{getLast4(account?.number)}
                </div>
              </div>
              {bankAccount.number === account.number ? (
                <div>
                  <CheckIcon className={styles.checkIcon} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.addAccountButtonContainer}>
        <CTA
          text="Añadir cuenta"
          size="large"
          onClick={() => setIsAddAccountModalOpen(true)}
        />
      </div>
      <AddBankAccount
        isOpen={isAddAccountModalOpen}
        setIsOpen={setIsAddAccountModalOpen}
        callback={handleNewAccountSuccess}
        setReqBank={setReqBank}
        bankAccounts={bankAccounts}
        setBankAccounts={setBankAccounts}
      />
    </div>
  );
};
/*
const bankAccountShape = {
  bankId: PropTypes.number.isRequired,
  bank: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
  }),
  accountNumber: PropTypes.string.isRequired,

  isDefault: PropTypes.bool.isRequired,
};

ChooseBankAccount.propTypes = {
  bankAccount: PropTypes.exact(bankAccountShape),
  bankAccounts: PropTypes.arrayOf(PropTypes.exact(bankAccountShape)),
  handleAccountChange: PropTypes.func.isRequired,
  setBankAccount: PropTypes.func.isRequired,
  setBankAccounts: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
};
*/
export default ChooseBankAccount;
