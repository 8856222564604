export default {
  apiUrl: process.env.API_URL,
  landingPageUrl: process.env.LANDING_URL,
  registrationUrl: `${process.env.LANDING_URL}/registrate`,
  termsUrl: `${process.env.LANDING_URL}/terminos`,
  helpLink: `https://wa.me/593939038128?text=¡Hola Misú! Necesito ayuda.`,
  onboardingLink: process.env.ONBOARDING_URL,
  // onboardingLink: 'https://onboarding.misu.ec?start=true',
  minPasswordLength: 8,
  userStatuses: {
    pending: 'PENDING',
    approved: 'ENABLED',
    disabled: 'DISABLED',
  },

  UserPerfilaStatuses: {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CANCELED: 'canceled',
    IN_REVIEW: 'in-review',
  },

  loanStatuses: {
    incomplete: 'incompleto',
    open: 'Solicitado',
    granted: 'Depositado',
    denied: 'Denegado',
    paid: 'Pagado',
    partiallyPaid: 'parcialmente_pagado',
    overdue: 'Vencido',
    cancelled: 'cancelado',
    uncollectible: 'incobrable',
    Anulado: 'Anulado',
  },

  product: {
    anticipo: 'PR-MSU-155',
    anticipoTurbo: 'PR-MSU-156',
    pagoPlazo: 'PR-MSU-157',
    pagoPlazoTurbo: 'PR-MSU-158',
  },

  groupProduct: {
    anticipo: 'GR-MSU-155',
    pagoPlazo: 'GR-MSU-156',
  },

  groupedFeedEnv: {
    initial: 'INITIAL',
    lateCharge: 'LATE-CHARGE',
    quotas: 'QUOTAS',
  },


};
