import axios from '../lib/axios';

export const getQuotas = (data) => {
  return axios.post('/credit/generate-number-quotes', data);
};

export const getValidProduct = (data) => {
  return axios.post('/product/request-amount', data);
};

export const generateQuotas = (data) => {
  return axios.post('/credit/quotas/fees/list', data);
};

export const createLoan = (data) => {
  return axios.post('/loans', data);
};

export const confirmLoanRequest = (data) => {
  return axios.post(`/credit/create`, data);
};
