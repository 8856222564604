import config from 'config';

const {
  open,
  granted,
  denied,
  paid,
  partiallyPaid,
  overdue,
  cancelled,
  uncollectible,
  Solicitado,
  Anulado,
} = config.loanStatuses;

export default {
  [open]: '#ff8f0e',
  [granted]: '#0055bc',
  [denied]: '#545a69',
  [paid]: '#05690d',
  [partiallyPaid]: '#5aa451',
  [overdue]: '#b3063d',
  [cancelled]: '#545a69',
  [uncollectible]: '#b3063d',
  ['En tránsito']: '#4361EE',
  [Anulado]: '#545a69',
};
