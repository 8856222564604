import React from 'react';
import PropTypes from 'prop-types';
import bcn from 'assets/banks/bcn.jpeg';
import bolivariano from 'assets/banks/bolivariano.png';
import guayaquil from 'assets/banks/guayaquil.png';
import internacional from 'assets/banks/internacional.jpeg';
import jep from 'assets/banks/jep.png';
import machala from 'assets/banks/machala.png';
import pacifico from 'assets/banks/pacifico.png';
import pichincha from 'assets/banks/pichincha.png';
import produbanco from 'assets/banks/produbanco.jpeg';
import banco from 'assets/banks/banco.png';

const BankLogo = ({ styles, logo, alt }) => {
  switch (logo) {
    case 'bcn':
      return <img className={styles} src={bcn} alt={`${alt} logo`} />;
    case 'banco-bolivariano':
      return <img className={styles} src={bolivariano} alt={`${alt} logo`} />;
    case 'banco-guayaquil':
      return <img className={styles} src={guayaquil} alt={`${alt} logo`} />;
    case 'banco-internacional':
      return <img className={styles} src={internacional} alt={`${alt} logo`} />;
    case 'cooperativa-jep':
      return <img className={styles} src={jep} alt={`${alt} logo`} />;
    case 'banco-machala':
      return <img className={styles} src={machala} alt={`${alt} logo`} />;
    case 'banco-pacifico':
      return <img className={styles} src={pacifico} alt={`${alt} logo`} />;
    case 'banco-pichincha':
      return <img className={styles} src={pichincha} alt={`${alt} logo`} />;
    case 'produbanco':
      return <img className={styles} src={produbanco} alt={`${alt} logo`} />;
    default:
      return <img className={styles} src={banco} alt={`${alt} logo`} />;
  }
};

BankLogo.propTypes = {
  logo: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.any,
};

export default BankLogo;
