import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RiTimeLine as SlowIcon } from '@react-icons/all-files/ri/RiTimeLine';
import { RiFlashlightLine as FastIcon } from '@react-icons/all-files/ri/RiFlashlightLine';
import { getUserBankAccounts } from 'api/user';
import { getProcessingOptions } from 'api/bank';
import useIsMounted from 'hooks/useIsMounted';
import Main from './components/MainScreen';
import Review from './components/Review';
import Confirmation from './components/Confirmation';
import ChooseProcessingOption from './components/ChooseProcessingOption';
import ChooseBankAccount from './components/ChooseBankAccount';
import ChooseGroup from './components/GroupOptions';
import ChooseBudget from './components/ChooseBudgetOption/ChooseBudgetOption';

const addIconsToProcessingOptions = (processingOptions) => {
  // They should come sorted by processing time (slowest first)
  return processingOptions.map((option, i) => {
    if (i === 0) {
      option.icon = SlowIcon;
      return { ...option, icon: SlowIcon };
    } else {
      return { ...option, icon: FastIcon };
    }
  });
};

const LoanForm = ({ defaultOptions, handleModalClose, setIsCompleted }) => {
  const isMounted = useIsMounted();

  const [amount, setAmount] = useState('');
  const formattedOptions = defaultOptions;
  //bank
  const [bankAccounts, setBankAccounts] = useState(defaultOptions.bankAccounts);
  const [bankAccount, setBankAccount] = useState(defaultOptions.bankAccounts[0]);

  //group
  const [groups, setGroups] = useState(defaultOptions.groups);
  const [oldGroups, setOldGroups] = useState(defaultOptions.groups);

  const [group, setGroup] = useState(defaultOptions.groups[0]);
  //processing
  const [processingOption, setProcessingOption] = useState(
    formattedOptions.groups[0].products[0],
  );
  const [processingOptions, setProcessingOptions] = useState(formattedOptions.groups);
  //quote
  const [quotes, setQuotes] = useState(defaultOptions);
  const [quote, setQuote] = useState(defaultOptions);
  //
  const [screen, setScreen] = useState('main');
  const [loan, setLoan] = useState(null);
  const [termId, setTermId] = useState(null);

  const handleAccountChange = async (oldBankId, newBankId) => {
    if (oldBankId === newBankId) return;

    //const newProcessingOptions = await getProcessingOptions(newBankId);
    //  const newFormattedOptions = addIconsToProcessingOptions(newProcessingOptions);

    if (isMounted.current) {
      //setProcessingOptions(newFormattedOptions);
      // setProcessingOption(newFormattedOptions[1] || null); // Choose the fast option by default
    }
  };

  const handleGroupChange = async (oldGroupkId, newGroupId) => {
    if (oldGroupkId === newGroupId) return;

    //   const newProcessingOptions = await getProcessingOptions(newBankId);
    // const newFormattedOptions = addIconsToProcessingOptions(newProcessingOptions);

    if (isMounted.current) {
      //  setProcessingOptions(newFormattedOptions);
      // setProcessingOption(newFormattedOptions[1] || null); // Choose the fast option by default
    }
  };

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        setProcessingOption(group.products[0] || []);

        if (isMounted.current) {
        }
      } catch (error) {
        console.error('Error al obtener las cuotas:', error);
      }
    };

    fetchQuotes();
  }, [group]);

  const screens = {
    main: (
      <Main
        group={group}
        bankAccount={bankAccount}
        processingOption={processingOption}
        quote={quote}
        setLoan={setLoan}
        setTermId={setTermId}
        setScreen={setScreen}
        handleModalClose={handleModalClose}
        amount={amount}
        setAmount={setAmount}
      />
    ),

    chooseAccount: (
      <ChooseBankAccount
        setBankAccount={setBankAccount}
        setBankAccounts={setBankAccounts}
        setScreen={setScreen}
        bankAccounts={bankAccounts}
        bankAccount={bankAccount}
        handleAccountChange={handleAccountChange}
      />
    ),
    chooseGroup: (
      <ChooseGroup
        amount={amount}
        setGroup={setGroup}
        setGroups={setGroups}
        setScreen={setScreen}
        groups={groups}
        group={group}
        handleGroupChange={handleGroupChange}
      />
    ),

    chooseOption: (
      <ChooseProcessingOption
        group={group}
        bankAccount={bankAccount}
        processingOption={processingOption}
        processingOptions={processingOptions}
        setProcessingOptions={setProcessingOptions}
        setProcessingOption={setProcessingOption}
        setScreen={setScreen}
      />
    ),
    chooseQuote: (
      <ChooseBudget
        processingOption={processingOption}
        bankAccount={bankAccount}
        amount={amount}
        quote={quote}
        quotes={quotes}
        setQuote={setQuote}
        setQuotes={setQuotes}
        setScreen={setScreen}
      />
    ),
    review: (
      <Review
        loan={loan}
        termId={termId}
        setLoan={setLoan}
        setIsCompleted={setIsCompleted}
        setScreen={setScreen}
      />
    ),
    confirmation: <Confirmation loan={loan} handleModalClose={handleModalClose} />,
  };
  /*
  useEffect(() => {
    const fetchBankAccounts = async () => {
      const accounts = await getUserBankAccounts();

      setBankAccounts(accounts);
    };

    fetchBankAccounts();
  }, []);
*/
  return screens[screen];
};
/*
LoanForm.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  setIsCompleted: PropTypes.func.isRequired,
  defaultOptions: PropTypes.exact({
    bankAccount: PropTypes.exact({
      id: PropTypes.number.isRequired,
      bank: PropTypes.exact({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        logoUrl: PropTypes.string.isRequired,
      }),
      number: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isDefault: PropTypes.bool.isRequired,
    }),
    processingOptions: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        fee: PropTypes.number.isRequired,
        hours: PropTypes.number.isRequired,
        bankId: PropTypes.number.isRequired,
        expectedFundingDate: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        icon: PropTypes.func,
        hoursAdjusted: PropTypes.number.isRequired,
      }),
    ),
  }),
};
*/
export default LoanForm;
