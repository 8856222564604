import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { toast } from 'react-hot-toast';
import Checkbox from 'components/Inputs/Checkbox';
import CTA from 'components/CallToAction';
import { getTyC, getTyR, getTyRByUserLoanTerm } from 'api/termUser';
import useIsMounted from 'hooks/useIsMounted';
import { createTyC } from 'api/termUser';
import styles from './terms.module.scss';
import Loading from 'components/Loading';
import TermsNotAccepted from '../TermsNotAccepted/TermsNotAccepted';
import config from 'config';
import useStore from 'store';
const TermsContent = ({
  error,
  loading,
  type,
  data,
  setIsOpen,

  callback = () => {},
  callClose = () => {},
  callAction2 = () => {},
}) => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const scrollableRef = useRef(null);
  const contentRef = useRef(null);
  const footerRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const isMobile =
        typeof window.matchMedia === 'function'
          ? window.matchMedia('(max-width: 767px)').matches
            ? true
            : false
          : false;

      if (isMobile) {
        if (scrollableRef.current && contentRef.current && footerRef.current) {
          const value = type == 'TYC' ? 40 : 60;
          const cRH = Number(contentRef.current.clientHeight);
          const fRH = Number(footerRef.current.clientHeight);
          scrollableRef.current.style.height = `${cRH - fRH - value}px`;
          scrollableRef.current.style.display = `block`;
        }
      } else {
        if (scrollableRef.current) {
          scrollableRef.current.style.maxHeight = '400px';
          scrollableRef.current.style.display = `block`;
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
      if (Number(scrollTop) + Number(clientHeight) > Number(scrollHeight) - 100)
        setIsRead(true);
    };

    if (scrollableRef.current)
      scrollableRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (scrollableRef.current)
        scrollableRef.current.removeEventListener('scroll', handleScroll);

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type == 'TYC') {
      setIsLoading(true);
      try {
        await createTyC(data);
        window.location.reload();
      } catch (error) {
        if (isMounted.current) {
          setIsLoading(false);
          toast.error(error.response?.data);
        }
      }
    } else {
      callback(data);

      if (isMounted.current) {
        setIsLoading(false);
        setIsOpen(false);
      }
    }
  };

  const actions = {
    TYC: (
      <>
        {isRead ? (
          <div className={styles.footerTermAgreed}>
            <Checkbox
              isChecked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
              label={<span>{data.messageAgreed}</span>}
            />
          </div>
        ) : (
          <div className={styles.footerTermAlert}>{data.message}</div>
        )}

        <CTA
          text="Aceptar"
          size="large"
          form="tyC"
          isLoading={isLoading}
          onClick={handleSubmit}
          disabled={!isAgreed}
        />
      </>
    ),
    // TYR: (
    //   <div style={{ marginBottom: "4px", display: "flex" }}>
    //     {type == 'TYR' && (
    //       <div style={{ flex: 1, paddingRight: '10px' }}>
    //         <button className={`${styles.button} ${styles["large"] || ''}`} onClick={callAction2}>
    //           Ver anexo
    //         </button>
    //       </div>
    //     )}
    //     <div style={{ flex: 1, paddingLeft: '10px', }}> <CTA text="OK" size="large" form="tyR" onClick={handleSubmit} /></div>
    //   </div>
    // ),
    TYR: (
      <div style={{ marginBottom: '4px' }}>
        <CTA text="OK" size="large" form="tyR" onClick={handleSubmit} />
        {type == 'TYR' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <a
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={callAction2}
            >
              Ver anexo
            </a>
          </div>
        )}
      </div>
    ),
    'TYR-IWillPay': <CTA text="OK" size="large" form="tyR" onClick={handleSubmit} />,
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerAction}>
        {/* <p className={styles.title}>{data.termTitle}</p> */}
        <span></span>
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => {
            setIsOpen(false);
            callClose();
          }}
        />
      </div>
      <div className={styles.content} ref={contentRef}>
        <div className={styles.scrollable} ref={scrollableRef}>
          {loading && (
            <div className={styles.termLoading}>
              <Loading isFluid={true} />
            </div>
          )}
          {error != '' && <div className={styles.termError}>{error}</div>}
          <div dangerouslySetInnerHTML={{ __html: data.termValue }}></div>
        </div>
        <div
          ref={footerRef}
          className={type == 'TYC' ? styles.footerTerm : styles.footerTermTyr}
        >
          <div
            style={{ display: data.termValue != '' ? 'block' : 'none', width: '100%' }}
          >
            {actions[type]}
          </div>
        </div>
      </div>
    </div>
  );
};

const TermsModal = ({ type, isOpen, setIsOpen, callback = () => {} }) => {
  const user = useStore((state) => state.user);
  const { approved } = config.userStatuses;

  const defaultData = {
    termTitle: '',
    termValue: '',
    messageAgreed: '',
    message: '',
    termId: 0,
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState(defaultData);
  const [tyCNotAcceptedModalOpen, setTyCNotAcceptedModalOpen] = useState(false);

  const getTerms = async () => {
    setLoading(true);
    try {
      setError('');
      setData(defaultData);
      const terms = type == 'TYC' ? await getTyC() : await getTyR();
      if (!terms.data) {
        setError(terms.message);
      } else {
        if (type == 'TYC') {
          //  if (terms.requiresAcceptanceTerms) {

          terms.termTitle = terms.data.termTitle || 'Términos y condiciones';
          terms.termValue = terms.data.termValue || '<h1>Contenido no disponible</h1>';
          terms.messageAgreed =
            terms.data.messageAgreed || 'Acepto los términos y condiciones';
          terms.message =
            terms.data.message || 'Por favor, lea los términos y condiciones';
          terms.id = terms.data.id || 0;
          terms.requiresAcceptanceTerms = terms.data.requiresAcceptanceTerms || false;

          if (terms.data) {
            setData(terms);
          } else {
            setIsOpen(false);
          }
        } else {
          setData(terms);
        }
      }
    } catch (error) {
      setError(error.response?.data);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseModal = async () => {
    setIsOpen(false);
    handleTyCNotAcceptedOpenModal();
  };

  const handleTyCNotAcceptedOpenModal = async () => {
    if (type == 'TYC')
      if (user.approvalStatus === approved)
        setTimeout(() => setTyCNotAcceptedModalOpen(true), 250);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleCloseModal()}
        className={styles.modal}
        overlayClassName={styles.overlay}
        onAfterOpen={getTerms}
      >
        <TermsContent
          loading={loading}
          type={type}
          data={data}
          callback={callback}
          setIsOpen={setIsOpen}
          error={error}
          callClose={handleTyCNotAcceptedOpenModal}
        />
      </Modal>
      <TermsNotAccepted
        isOpen={tyCNotAcceptedModalOpen}
        setIsOpen={setTyCNotAcceptedModalOpen}
        callback={() => {
          // setTyCNotAcceptedModalOpen();
          setIsOpen(true);
        }}
      />
    </>
  );
};

export const TermsTYRModal = ({ isOpen,body, setIsOpen, callback = () => {} }) => {
  const defaultData = {
    termTitle: '',
    termValue: '',
    messageAgreed: '',
    message: '',
    termId: 0,
    iwillpay: '',
    promissory: '',
  };
  console.log(body)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState(defaultData);
  const [tyRIWillPayModalOpen, setTyRIWillPayModalOpen] = useState(false);
  const getTerms = async () => {
    setLoading(true);
    try {
      setError('');
      setData(defaultData);
      const terms = await getTyRByUserLoanTerm(body);

      defaultData.termValue = terms?.data?.templateTyR;
      defaultData.promissory = terms?.data?.templatePromissory;

      if (!terms.data) {
        setError(terms.message);
      } else {
        setData(defaultData);
      }
    } catch (error) {
      setError(error.response?.data);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseModal = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleCloseModal()}
        className={styles.modal}
        overlayClassName={styles.overlay}
        onAfterOpen={getTerms}
      >
        <TermsContent
          loading={loading}
          type={'TYR'}
          data={data}
          callback={callback}
          setIsOpen={setIsOpen}
          error={error}
          callAction2={() => setTyRIWillPayModalOpen(true)}
        />
      </Modal>

      <TermsTYRIWillPayModal
        termValue={data}
        isOpen={tyRIWillPayModalOpen}
        setIsOpen={setTyRIWillPayModalOpen}
      />
    </>
  );
};

export const TermsTYRIWillPayModal = ({ termValue, isOpen, setIsOpen }) => {
  const defaultData = {
    termTitle: 'Pagaré',
    termValue: termValue.promissory,

    messageAgreed: '',
    message: '',
    termId: 0,
  };
  const handleCloseModal = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleCloseModal()}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <TermsContent
          loading={false}
          type={'TYR-IWillPay'}
          data={defaultData}
          setIsOpen={setIsOpen}
          error={''}
        />
      </Modal>
    </>
  );
};

TermsContent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  callback: PropTypes.func,
  setIsOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  callClose: PropTypes.func,
  callAction2: PropTypes.func,
};

TermsModal.propTypes = {
  type: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

TermsTYRModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

TermsTYRIWillPayModal.propTypes = {
  termValue: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
export default TermsModal;
