import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

import Loading from 'components/Loading';
import useStore from 'store';
import useIsMounted from 'hooks/useIsMounted';

import { getAllLoans } from 'api/user';
import { startCase, getLast4 } from 'utils/strings';
import { toDollars } from 'utils/currency';

import { toCompactDate } from 'utils/dates';
import { formatBankAccount, toRGBA } from '../../utils/helpers';
import statusColors from '../../utils/statusColors';
import styles from './loan-list.module.scss';
import useTerms from 'hooks/useTerms';

const ListFooter = ({ count, totalCount, totalPages, page, handlePageChange }) => {
  if (count === 0) return null;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <span className={styles.footerText}>
          {totalCount} {totalCount === 1 ? 'anticipo' : 'anticipos'}
        </span>
        <div className={styles.footerButtonContainer}>
          {page > 1 && (
            <button
              className={`${styles.footerButton} ${styles.footerButtonPrevious}`}
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Anterior
            </button>
          )}
          <button
            className={styles.footerButton}
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
          >
            Siguientes
          </button>
        </div>
      </div>
    </div>
  );
};

const disableStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  visibility: 'hidden',
};

const ListBody = ({ loans, setIsFormOpen, handleClick }) => {
  const { user } = useStore((state) => state);
  console.log(user.data.wallet.availableQuota);

  if (loans.length === 0) {

    const { existsTermsConditionsToAcceptAndOnboardingData, isLoading } = useTerms();
    const [tyCModalOpen, setTyCModalOpen] = useState(false);

    const handleExistsTermsConditionsToAccept = async () => {
      existsTermsConditionsToAcceptAndOnboardingData((requiresAcceptanceTerms) => {
        if (requiresAcceptanceTerms) setTyCModalOpen(true);
        else {
          setIsFormOpen(true);
        }
      });
    };
    return (
      <tbody>
        <tr>
          <td colSpan="100%" className={styles.emptyStateRow}>
            <div
              style={Number(user?.data.wallet?.availableQuota) <= 0 ? disableStyle : {}}
              className={styles.emptyStateContent}
            >{/*
              <span className={styles.emptyStateTitle}>Aún no tienes anticipos</span>
              <span className={styles.emptyStateCta} onClick={() => handleExistsTermsConditionsToAccept(true)}>
                Solicitar anticipo
              </span>
              */}
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {loans.map((loan, index) => (
        <tr key={index} className={styles.row} onClick={() => handleClick(loan)}>
          <td>{toCompactDate(loan.createdAt)}</td>
          <td className={styles.hideOnMobile}>
            {getLast4(loan.bankAccount.accountNumber)} • ({loan.bankAccount.bank})
          </td>

          <td className={styles.status}>
            <span
              style={{
                background: toRGBA(statusColors[loan.status], 0.1),
                color: statusColors[loan.status],
              }}
            >
              {loan.status == 'incobrable'
                ? startCase('vencido')
                : startCase(loan.status)}
            </span>
          </td>

          <td className={styles.field}>${loan.value2}</td>
          <td className={styles.field}>${loan.retention2}</td>
          <td className={styles.field}>${loan.total2}</td>
          <td className={styles.field}>
            <button className={styles.detailsButton} onClick={() => handleClick(loan)}>
              Ver detalles
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const LoanList = ({ setLoanId, setIsModalOpen }) => {
  const isMounted = useIsMounted();
  const tableRef = useRef();

  const { user, setIsFormOpen } = useStore((state) => state);
  const [loans, setLoans] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const limit = 5;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(async () => {
    const start = Date.now();
    const minMS = 500;

    try {
      const res = await getAllLoans(page, limit);

      if (isMounted.current) {
        setLoans(res?.data.data);

        setTotalPages(Math.ceil(res.data.total / limit));
        setTotalCount(res.data.total);
      }

      const end = Date.now();
      const time = end - start;
      const remaining = minMS - time;

      if (remaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, remaining));
      }
    } catch (error) {
      if (isMounted.current) toast.error('Error al obtener los anticipos');
    } finally {
      if (isMounted.current) setIsInitialLoading(false);
    }
  }, []);

  const handlePageChange = async (newPage) => {
    setIsNewPageLoading(true);
    scrollToTop();

    const start = Date.now();
    const minMS = 500;

    try {
      const res = await getAllLoans(newPage, limit);
      const end = Date.now();
      const time = end - start;
      const remaining = minMS - time;

      if (remaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, remaining));
      }

      if (isMounted.current) {
        setLoans(res.data.data);
        setPage(newPage);
      }
    } catch (error) {
      if (isMounted.current) toast.error(error.response?.data);
    } finally {
      if (isMounted.current) setIsNewPageLoading(false);
    }
  };

  const handleClick = (loan) => {
    setLoanId(loan.id);
    setIsModalOpen(true);
  };

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (isInitialLoading) {
    return (
      <div className={styles.initialLoadingContainer}>
        <Loading isFluid={true} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div ref={tableRef} className={styles.tableWrapper}>
          {isNewPageLoading && (
            <div className={styles.newPageLoadingContainer}>
              <Loading isFluid={true} />
            </div>
          )}
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Fecha solicitud</th>
                  <th className={styles.hideOnMobile}>Cuenta</th>
                  <th className={styles.statusField}>Estatus</th>
                  <th className={styles.amountField}>Anticipo</th>
                  <th className={` ${styles.amountField}`}>fee</th>
                  <th className={styles.amountField}>Total a pagar</th>
                  <th className={styles.amountField}>Acciones</th>
                </tr>
              </thead>
              <ListBody
                loans={loans}
                setIsFormOpen={setIsFormOpen}
                handleClick={handleClick}
              />
            </table>
          </div>
          <ListFooter
            totalCount={totalCount}
            totalPages={totalPages}
            count={loans.length}
            page={page}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

LoanList.propTypes = {
  setLoanId: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

ListBody.propTypes = {
  loans: PropTypes.array.isRequired,
  setIsFormOpen: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ListFooter.propTypes = {
  count: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default LoanList;
