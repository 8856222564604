import React, { useEffect, useRef, useState } from 'react';
import styles from './biometrics.module.scss';
import onboardingStyles from '../../onboarding.module.scss';
import PropTypes from 'prop-types';
import CTA from 'components/CallToAction';
import { toast } from 'react-hot-toast';
import { biometrics } from 'api/onboarding';
import useIsMounted from 'hooks/useIsMounted';
import config from 'config';
import biometricsLogo from 'assets/biometrics.png';
const Biometrics = ({ biometricsData, callback = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const id4faceRef = useRef(null);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (biometricsData.token != '') {
      // document.querySelector('eclipsoft-id4face').addEventListener('result', (e) => { })
      const handleResult = async (event) => {
        if (event.detail.status === 'VERIFIED') {
          await onSubmit(event.detail.match, event.detail.requestId);
        } else {
          toast.error('Vuelve a intentarlo');
        }
      };

      const handleFailed = (event) => {
        console.log('handleFailed: ', event);
        toast.error('Vuelve a intentarlo');
      };

      if (id4faceRef.current) {
        id4faceRef.current.addEventListener('result', handleResult);
        id4faceRef.current.addEventListener('failed', handleFailed);
      }

      return () => {
        if (id4faceRef.current) {
          id4faceRef.current.removeEventListener('result', handleResult);
          id4faceRef.current.removeEventListener('failed', handleFailed);
        }
      };
    }
  }, [biometricsData.token]);

  const capturePhoto = async () => {
    setLoading(true);
    await handleLoadId4face();
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setLoading(false);
    id4faceRef.current.start();
  };

  const onSubmit = async (match, requestId) => {
    try {
      setLoading(true);
      setError('');
      await biometrics({
        token: biometricsData.token,
        match,
        requestId,
      });
      toast.success('¡Éxito!');
      callback();
    } catch (error) {
      toast.error(error.response?.data);
      setError(error.response?.data);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  const handleLoadId4face = async () => {
    try {
      const id4faceElement = id4faceRef.current;
      if (id4faceElement) {
        id4faceElement.token = biometricsData.token;
        await id4faceElement.load({
          minMatch: String(biometricsData.minMatch), //"98", //
          faceMask: false,
          blink: true,
          env: config.eclipsoftId4faceEnv,
          callbackUrl: config.eclipsoftId4faceUrlCallbackUrl,
          checkId: {
            id: biometricsData.identityNumber,
            dactilar: biometricsData.fingerprint,
          },
        });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={styles.content}>
      <div className={onboardingStyles.centerContainer}>
        <h3 style={{ marginBottom: '15px' }}>Valida tu identidad</h3>
        <img
          src={biometricsLogo}
          alt="biometricsLogo"
          style={{ paddingRight: '30px', paddingLeft: '30px', width: '250px' }}
        />
      </div>

      <p className={styles.subtitle}>Sigue las siguientes indicaciones:</p>
      <ol className={styles.list}>
        {/* <li >Ubica tu cabeza dentro del círculo.</li>
        <li >Ubícate en un lugar con fondo claro y con buena iluminación.</li> */}
        <li>
          Dale clic a <strong>Tomar foto</strong> y ubica tu rostro dentro del círculo.
        </li>
        <li>No uses gorras, lentes y ningún objeto que oculte tu rostro.</li>
        <li>
          Recuerda <strong>parpadear.</strong>
        </li>
      </ol>

      {error != '' && <div className={onboardingStyles.alertError}>{error}</div>}
      <div style={{ margin: '15px' }}></div>
      <CTA
        text={'Tomar foto'}
        size="large"
        form="Biometrics"
        onClick={capturePhoto}
        isLoading={loading}
      />

      <eclipsoft-id4face ref={id4faceRef}></eclipsoft-id4face>
    </div>
  );
};

Biometrics.propTypes = {
  callback: PropTypes.func,
  biometricsData: PropTypes.shape({
    token: PropTypes.string.isRequired,
    identityNumber: PropTypes.string.isRequired,
    fingerprint: PropTypes.string.isRequired,
    minMatch: PropTypes.number.isRequired,
  }).isRequired,
};

export default Biometrics;
