import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import Loading from 'components/Loading';
import Modal from 'react-modal';
import AddBankAccount from 'components/AddBankAccount';
import LoanForm from './LoanForm';
import useStore from 'store';
import useIsMounted from 'hooks/useIsMounted';
import { getLoanDefaults } from 'api/user';
import styles from './loan-modal.module.scss';
import sorryPng from 'assets/decorative-images/Sorry.png';
import ShowMessage from '../Home/components/ShowMessage';
import AddBankAccountModal from 'components/AddBankAccount';

const ModalContent = ({
  isLoading,
  handleModalClose,
  setIsCompleted,
  defaultOptions,
  initLoanForm,
}) => {
  const [isBankAccountFormOpen, setIsBankAccountFormOpen] = useState(false);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading isFluid={true} />
      </div>
    );
  }

  if (!defaultOptions) {
    return (
      <div className={styles.errorContainer}>
        <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        <h3 className={styles.errorTitle}>Error al iniciar solicitud</h3>
        <p className={styles.errorMessage}>
          Lo sentimos, hubo un error al iniciar tu solicitud. Por favor, intentalo
          nuevamente.
        </p>
        <img src={sorryPng} alt="Error" className={styles.errorImage} />
      </div>
    );
  }

  if (!defaultOptions.bankAccounts) {
    return (
      <div className={styles.errorContainer}>
        <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        <h3 className={styles.errorTitle}>Registra una cuenta bancaria</h3>
        <p className={styles.errorMessage}>
          Debes añadir una cuenta de banco para poder solicitar un anticipo. Recuerda que
          la cuenta debe estar a tu nombre para que sea considerada válida.
        </p>
        <button
          className={styles.addAccountButton}
          onClick={() => setIsBankAccountFormOpen(true)}
        >
          Registrar cuenta
        </button>
        <AddBankAccount
          isOpen={isBankAccountFormOpen}
          setIsOpen={setIsBankAccountFormOpen}
          callback={initLoanForm}
        />
      </div>
    );
  }

  return (
    <LoanForm
      defaultOptions={defaultOptions}
      handleModalClose={handleModalClose}
      setIsCompleted={setIsCompleted}
    />
  );
};

ModalContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initLoanForm: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setIsCompleted: PropTypes.func.isRequired,
  defaultOptions: PropTypes.object,
};

const LoanFormModal = () => {
  const { isFormOpen, setIsFormOpen } = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isBankExist, setIsBankExist] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const isMounted = useIsMounted();
  const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);
  const [isBankAccount, setIsBankAccount] = useState(false);

  const handleModalClose = () => {
    if (isCompleted) {
      return window.location.reload(false);
    }

    setIsFormOpen(false);
    setIsLoading(true);
    setDefaultOptions(null);
  };

  const initLoanForm = async () => {
    setIsLoading(true);

    const start = Date.now();
    const minTime = 800;

    try {
      const data = await getLoanDefaults();

      if (data.data.bankAccounts.length === 0) {
        setIsBankExist(true);
        if (isMounted.current) setDefaultOptions({ bankAccounts: null });
        return;
      }
      setIsBankExist(false);

      if (isMounted.current) setDefaultOptions(data.data);
    } catch (error) {
      if (isMounted.current) setDefaultOptions(null);
    } finally {
      const end = Date.now();
      const time = end - start;
      const remainingTime = minTime - time;

      if (remainingTime > 0) {
        await new Promise((resolve) => setTimeout(resolve, remainingTime));
      }

      if (isMounted.current) setIsLoading(false);
    }
  };

  // Efecto para actualizar el componente cuando se cierra el modal de nueva cuenta
  useEffect(() => {
    if (!isNewAccountModalOpen) {
      initLoanForm(); // Reinicia el formulario
    }
  }, [isNewAccountModalOpen]);

  return (
    <Modal
      isOpen={isFormOpen}
      onRequestClose={handleModalClose}
      onAfterOpen={initLoanForm}
      contentLabel="Nuevo anticipo"
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      {isBankExist ? (
        <>
          <div style={{ alignItems: 'center', margin: '0 auto', textAlign: 'center' }}>
            <ShowMessage
              title="Registro de cuenta bancaria"
              message="Para solicitar un anticipo, primero debes registrar una cuenta bancaria."
              showButton={false}
              onClose={handleModalClose}
            />
            <button
              className={styles.addAccountButton}
              onClick={() => setIsNewAccountModalOpen(true)}
            >
              Registrar cuenta
            </button>
          </div>
          <br />
          <AddBankAccountModal
            isOpen={isNewAccountModalOpen}
            setIsOpen={setIsNewAccountModalOpen}
            callback={setIsBankAccount}
          />
        </>
      ) : (
        <ModalContent
          isLoading={isLoading}
          defaultOptions={defaultOptions}
          initLoanForm={initLoanForm}
          handleModalClose={handleModalClose}
          setIsCompleted={setIsCompleted}
        />
      )}
    </Modal>
  );
};

export default LoanFormModal;
