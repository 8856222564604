import axios from '../lib/axios';

export const addBankAccount = (data) => {
  try {
    return axios.post('/user/bank-accounts/register', data);
  } catch (error) {
    return [];
  }
};

export const getBankAccountsUser = () => {
  return axios.get('/user/bank-accounts');
};

export const updateBankAccount = (accountId, data) => {
  return axios.put(`user/bank-account/partial-update/default/${accountId}`, data);
};

export const deleteBankAccount = (accountId) => {
  return axios.put(`user/bank-account/partial-update/disabled/${accountId}`);
};
