import React from 'react';
import useStore from 'store';
import config from 'config';
import styles from './no-access.module.scss';
import bookPng from 'assets/decorative-images/Book.png';

const { notRequested, pending, rejected, disabled } = config.userStatuses;

const content = {
  [notRequested]: {
    title: 'Necesitamos verificar tu identidad',
    subtitle:
      'Para acceder a todos los servicios de Misú, tu información debe ser verificada por nuestro equipo. Por favor, contáctanos para que podamos ayudarte.',
    image: bookPng,
  },
  [pending]: {
    title: 'Tu aplicación está siendo procesada',
    subtitle:
      'Nuestro equipo esta revisando que la información de registro sea correcta. Te enviaremos un correo electrónico en cuanto este aprobada.',
    image: bookPng,
  },
  [rejected]: {
    title: 'Tu aplicación ha sido rechazada',
    subtitle:
      'Lo sentimos, pero tu solicitud no ha sido aprobada. Si crees que esto ha sido un error, por favor contáctanos de inmediato.',
    image: bookPng,
  },
  [disabled]: {
    title:
      'Tu usuario en Misú ha sido temporalmente inhabilitado debido a pagos vencidos',
    subtitle:
      'Estamos comprometidos a ayudarte a resolver esta situación y entendemos posibles dificultades financieras. Por favor, contáctanos al 0939056361 para encontrar una solución.',
    image: bookPng,
  },
};

const NoAccess = () => {
  const user = useStore((state) => state.user);
  const status = user.userStatus.code === 'ENABLED' ? pending : null;
  /*
  const status = {
    notRequested: {
      title: 'Request Not Made',
      subtitle: 'You have not made any requests yet.',
    },
    inProgress: {
      title: 'Request In Progress',
      subtitle: 'Your request is currently being processed.',
    },
    image: bookPng,
    approvalStatus: true,
  };
*/
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{content[status]?.title}</h2>
        {typeof content[status]?.notRequested?.subtitle === 'string' ? (
          <p className={styles.subtitle}> {content[status].subtitle}</p>
        ) : (
          <>
            {content[status]?.notRequested?.subtitle.map((message) => {
              return (
                <div key={message.slice(0, 5)}>
                  <br />
                  <p className={message}> {message}</p>
                </div>
              );
            })}
          </>
        )}
        {typeof content[status]?.image !== 'undefined' && (
          <img className={styles.image} src={content[status].image} alt="" />
        )}
      </div>
    </div>
  );
};

export default NoAccess;
