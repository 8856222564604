import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import AddBankAccount from 'components/AddBankAccount';
import CTA from 'components/CallToAction';
import { startCase, getLast4 } from 'utils/strings';
import useIsMounted from 'hooks/useIsMounted';
import BankLogo from '../../../../../components/BankLogo';
import Loading from 'components/Loading';

import styles from './choose-budget-option.module.scss';
import { RiSettingsLine } from '@react-icons/all-files/ri/RiSettingsLine';
import { getQuotas } from '../../../../../api/loan';

const ChooseBudget = ({
  processingOption,
  bankAccount,
  amount,
  quote,
  quotes,
  handleGroupChange,
  setQuote,
  setQuotes,
  setScreen,
}) => {
  const body = {
    productId: processingOption.id,
    requestAmount: Number(amount),
    bankSlug: bankAccount.bank.slug,
  };

  const [newQuotes, setNewQuotes] = useState([]); // Define el estado para almacenar las nuevas cuotas
  const isMounted = useIsMounted();
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await getQuotas(body);

        if (isMounted.current) {
          setQuotes(response.data || []);
          setNewQuotes(response.data || []);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error al obtener las cuotas:', error);
        setIsLoading(false);
      }
    };

    fetchQuotes();
  }, []);

  const handleClick = async (item) => {
    setQuote(item);
    setTimeout(() => setScreen('main'), 300);
  };

  const handleNewAccountSuccess = (item) => {
    const newGroupList = [item, ...group];
    const currentGroupId = group.id;
    const newGroupId = item.id;

    if (isMounted.current) {
      setGroups(newGroupList);
      setGroup(item);
    }

    handleGroupChange(currentGroupId, newGroupId);
    setScreen('main');
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading isFluid={true} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Opciones de plazos Disponibles</span>
      </div>
      <div className={styles.accountListContainer}>
        <div className={styles.accountsList}>
          {' '}
          {/*JSON.stringify(newQuotes)*/}
          {newQuotes?.quoteNumbers?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleClick(item)}
              className={`${styles.account} ${
                item.quoteNumber === quote.quoteNumber ? styles.active : ''
              }`}
            >
              <RiSettingsLine className={styles.processingOptionIcon} />

              <div className={styles.bankLogoContainer}></div>

              <div className={styles.info}>
                <div className={styles.bankName}>
                  {' '}
                  <strong>{'Plazo'} </strong>
                </div>
                <div className={styles.accountDetails}>
                  {'• '}
                  {item.quoteNumber}
                  {' meses  '}
                </div>
              </div>

              {item.quoteNumber === quote.quoteNumber ? (
                <div>
                  <CheckIcon className={styles.checkIcon} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      {/*
      <div className={styles.addAccountButtonContainer}>
        <CTA
          text="Añadir Grupo"
          size="large"
          onClick={() => setIsAddAccountModalOpen(true)}
          />
      </div>
        */}
      <AddBankAccount
        isOpen={isAddAccountModalOpen}
        setIsOpen={setIsAddAccountModalOpen}
        callback={handleNewAccountSuccess}
      />
    </div>
  );
};

export default ChooseBudget;
